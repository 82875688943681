import * as Three from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { TTFLoader } from '../utils/three/TTFLoader';
import { SkeletonUtils } from 'three/examples/jsm/utils/SkeletonUtils';
import { addExtendMaterial } from '../utils/three/extend-material';

import React, { createRef } from 'react';
import styled from 'styled-components';
import {
  Layout,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { AntWrapper } from '../components/ant-wrapper';
import { globalMouseHelper } from '../utils/global-mouse';
import { Wtl3DScene } from '../utils/three/wtl-3d-scene';
import { getFileFormat } from '../utils/file-helpers';
import { WtlSpinner } from '../components/layout/wtl-spinner';
import { WtlShareBox, loaderMap } from '../components/layout/wtl-share-box';
import { navigate } from 'gatsby';
import { SharedNav } from '../components/shared-nav';

if (typeof window !== 'undefined') {
  window.removeEventListener('mousemove', globalMouseHelper);
  window.addEventListener('mousemove', globalMouseHelper);
}

addExtendMaterial(Three);

const {
  Content
} = Layout;

const DebugHelperOption = styled.div`
  display: inline-block;
  margin: 0 5px;
  padding: 0 5px;
  padding-right: 0;
  border-left: solid 1px #ccc;
  cursor: default;
  text-align: center;
  user-select: none;
  width: ${p => p.width ? `${p.width}px` : 'auto'};

  &[disabled] {
    opacity: .3;
  }

  i {
    font-size: 10px;
    opacity: ${p => p.inactive ? '1' : '.5'};
  }

  &:not([disabled]):hover {
    i {
      opacity: 1;
    }
  }
`;

const MissingFileLabel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ccc;

  .missing-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    h1 {
      display: inline-block;
      font-size: 42px;
      line-height: 60px;
      font-weight: bold;
      background-color: #000;
      padding: 20px;
      margin: 5px 0;
      border-radius: 20px 0px 20px 0px;
      color: #ffffff;
    }

    h2 {
      display: inline-block;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: bold;
      background-color: #1890ff;
      border: solid 1px #000;
      padding: 16px;
      margin: 5px 0;
      border-radius: 0px 20px 0px 20px;
      color: #ffffff;
      color: #fff;
      text-shadow:
        -1px -1px 0px rgba(0, 0, 0, .5),
        1px -1px 0px rgba(0, 0, 0, .5),
        1px 1px 0px rgba(0, 0, 0, .5),
        -1px 1px 0px rgba(0, 0, 0, .5);
      background-color: #108ee9;
      box-shadow: 0px 2px 0px #000;
      outline: none;
      transition: background-color .3s ease;
      cursor: pointer;

      &:active,
      &:hover {
        background-color: #40afff;
      }
    }
  }
`;

export default class Share extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: this.getRequestedFile(),
      loadingPreview: false,
      modelScale: 1.0,
      fileError: false
    };
  }

  componentDidMount() {
    this.addResizeListener();

    this.awaitFonts();
  }

  getRequestedFile() {
    if (typeof window === 'undefined' || !window.location) {
      return null;
    }

    const [ user, file ] = window.location.search.split(/[\?\&][qh]\=(.*?)/gm).filter(Boolean);

    if (!user || !file) {
      this.setState({ fileError: true });
      return null;
    }

    return `https://cdn.wtlstudio.com/${user}.wtlstudio.com/${file}`;
  }

  awaitFonts() {
    if (typeof document !== 'undefined' && document.onreadystatechange) {
      document.onreadystatechange = () => {
        if (document.readyState === 'complete') {
          this.forceUpdate();
        }
      }
    }
  }

  onWindowResize() {
    this.forceUpdate();
  }

  addResizeListener() {
    if (typeof window === 'undefined') {
      return;
    }

    window.removeEventListener('resize', this.onWindowResize.bind(this));
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  resourceCache = null;

  async getPreviewResources(renderer) {
    let hdriTexture;
    let hdriSource;
    let fontAwesome;
    let gridTexture;

    if (this.resourceCache) {
      if (this.resourceCache.hdriSource) {
        const pmremGenerator = new Three.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();

        const hdrCube = pmremGenerator.fromEquirectangular(this.resourceCache.hdriSource);
        pmremGenerator.dispose();

        this.resourceCache.hdri = hdrCube.texture;
      }

      return this.resourceCache;
    }

    await new Promise((resolve) => {
      new RGBELoader()
      .setDataType(Three.UnsignedByteType)
      .load('../cinema_lobby_1k.hdr', (texture) => {
        const pmremGenerator = new Three.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();

        const hdrCube = pmremGenerator.fromEquirectangular(texture);
        pmremGenerator.dispose();

        hdriSource = texture;
        hdriTexture = hdrCube.texture;

        resolve();
      });
    });

    await new Promise((resolve) => {
      new TTFLoader().load('../fonts/font-awesome/webfonts/fa-light-300.ttf', (json) => {
        const font = new Three.Font(json);
        
        fontAwesome = font;

        resolve();
      });
    });

    await new Promise((resolve) => {
      new Three.TextureLoader().load('../grid.png', texture => {
        texture.wrapS = Three.RepeatWrapping;
        texture.wrapT = Three.RepeatWrapping;
        texture.minFilter = Three.NearestFilter;
        texture.magFilter = Three.NearestFilter;
        texture.repeat.set(200, 100);

        gridTexture = texture;

        resolve();
      })
    });
    
    const resources = {
      hdri: hdriTexture,
      font: fontAwesome,
      hdriSource: hdriSource,
      grid: gridTexture
    };

    this.resourceCache = resources;

    return resources;
  }

  renderFilePreview(file) {
    if (!file) {
      return null;
    }

    const { loadingPreview, modelScale } = this.state;

    const { isImage, is3DModel } = getFileFormat(file);

    const extension = `${file}`.split('.').splice(-1, 1)[0].toLowerCase();
    let show3DModelPreview = is3DModel;
    const previewLoader = loaderMap[extension];

    if (!previewLoader) {
      show3DModelPreview = false;
    }

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <WtlSpinner
          text="Loading preview"
          spinning={loadingPreview}
        />
        <WtlShareBox file={file} modelScale={modelScale || 1.0} owner={false} offsetY={-100} />
        <Wtl3DScene
          key={file}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0
          }}
          width={window.innerWidth}
          height={window.innerHeight - 60}
          renderQuality={2}
          aoQuality={show3DModelPreview ? 1 : 0}
          rendererOptions={{
            preserveDrawingBuffer: true
          }}
          onSceneReady={async ({
            renderer,
            scene,
            camera,
            controls,
            effects,
            assets,
          }) => {
            this.setState({ previewReady: false });

            camera.position.set(0, 2, 10);
            camera.lookAt(new Three.Vector3());

            controls.enablePan = false;
            controls.minDistance = 4.0;
            controls.maxDistance = 10.0;
            controls.maxPolarAngle = Math.PI / 2 - .3;
            controls.minPolarAngle = .2;

            scene.background = new Three.Color(0xcccccc);
            scene.fog = new Three.FogExp2(0xcccccc, .01);

            let mesh;

            const {
              hdri,
              font,
              grid
            } = await this.getPreviewResources(renderer);

            scene.environment = hdri;

            try {
              if (show3DModelPreview) {
                controls.autoRotate = true;

                await new Promise((resolve) => {
                  new (previewLoader[2])().load(file, (result) => {
                    if (result.scene) {
                      mesh = result.scene;
                    } else if (result.object) {
                      mesh = result.object;
                    } else {
                      mesh = result;
                    }

                    scene.add(mesh);

                    mesh.traverse(child => {
                      if (child.material && child.material.map) {
                        child.material.map.anisotropy = 100;
                      }

                      if (child.material) {
                        assets.push(child.material.map);
                        assets.push(child.material.envMap);
                        assets.push(child.material.roughnessMap);
                        assets.push(child.material.metalnessMap);
                        assets.push(child.material.emissiveMap);
                        assets.push(child.material.alphaMap);
                      }
                    });

                    resolve();
                  }, () => {}, () => {
                    this.setState({ fileError: true });

                    resolve();
                  });
                });

                const box3 = new Three.Box3();
                box3.setFromObject(mesh);
                
                const meshSize = new Three.Vector3();
                box3.getSize(meshSize);

                const dScale = 2.0 / Math.max(meshSize.x, meshSize.y, meshSize.z);

                // For codepen preview
                this.setState({ modelScale: Math.min(1.0, dScale) });

                mesh.scale.multiplyScalar(Math.min(1.0, dScale));
              } else if (isImage) {
                controls.enableRotate = false;
                controls.maxDistance = 100.0;

                await new Promise((resolve) => {
                  new Three.TextureLoader().load(file, texture => {
                    texture.encoding = Three.sRGBEncoding;
                    texture.minFilter = Three.LinearFilter;
                    texture.magFilter = Three.LinearFilter;

                    assets.push(texture);

                    let ratio = 1.0;

                    if (texture.image) {
                      ratio = (texture.image.naturalWidth || texture.image.width) / (texture.image.naturalHeight || texture.image.height);
                    }

                    mesh = new Three.Mesh(
                      new Three.PlaneBufferGeometry(
                        2.0 * ratio,
                        2.0
                      ),
                      new Three.MeshStandardMaterial({
                        map: texture,
                        side: Three.DoubleSide,
                        roughness: 0
                      })
                    );

                    assets.push(mesh.material);

                    mesh.lookAt(camera.position);

                    resolve();
                  });
                });
              }
            } catch (error) {
              console.info({ error });
            }

            if (!mesh) {
              const geometry = new Three.TextBufferGeometry('', {
                font,
                size: 1.0,
                height: .1,
                curveSegments: 32,
                bevelEnabled: true,
                bevelThickness: .02,
                bevelSize: .02
              });

              mesh = new Three.Mesh(
                geometry,
                new Three.MeshStandardMaterial({ roughness: 0.5, metalness: 0.9, color: 0xffffff })
              );
              mesh.position.set(-1.0, 0, 0);

              assets.push(mesh.material);
            }

            mesh.traverse(child => {
              if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
              }
            });
            scene.add(mesh);

            // Add fresnel glow

            new Promise((resolve) => {
              const fresnelMaterial = Three.extendMaterial(Three.MeshPhongMaterial, {
                header: `
                  varying vec3 vNN;
                  varying vec3 vEye;
                `,
                vertex: {
                  '#include <fog_vertex>': `
                    mat4 LM = modelMatrix;
                    LM[2][3] = 0.0;
                    LM[3][0] = 0.0;
                    LM[3][1] = 0.0;
                    LM[3][2] = 0.0;

                    vec4 GM = LM * vec4(objectNormal.xyz, 1.0);
                    vNN = normalize(GM.xyz);
                    vEye = normalize(GM.xyz - cameraPosition);
                  `
                },
                fragment: {
                  'gl_FragColor = vec4( outgoingLight, diffuseColor.a );': `
                    gl_FragColor = vec4(0.65, 0.85, 1.0, 0.0);
                    gl_FragColor.rgb += 1.0 + min(dot(vEye, normalize(vNN)), 0.0);
                    gl_FragColor.w += .5 + min(dot(vEye, normalize(vNN)), 0.0);
                  `
                },
              });
              fresnelMaterial.transparent = true;

              const children = [];
              
              mesh.traverse(child => {
                if (child.isMesh && child.material && !child.material.skinning) {
                  children.push(child);
                }

                if (child.material && child.material.skinning && effects.ao) {
                  effects.ao.enabled = false;
                }
              });

              children.forEach(child => {
                const outline = SkeletonUtils.clone(child);
                outline.material = fresnelMaterial;
                outline.scale.multiplyScalar(1.0 + Number.MIN_VALUE);
                child.parent.add(outline);
              });

              assets.push(fresnelMaterial);

              resolve();
            });

            // Add ground

            const ground = new Three.Mesh(
              new Three.PlaneBufferGeometry(500, 500),
              new Three.MeshStandardMaterial({
                map: grid,
                roughness: .9,
                metalness: .9
              })
            );
            ground.rotation.x = -Math.PI / 2;
            ground.receiveShadow = true;

            assets.push(ground.material);

            scene.add(ground);

            const meshBox3 = new Three.Box3();
            meshBox3.setFromObject(mesh);

            const groundBox3 = new Three.Box3();
            groundBox3.setFromObject(ground);

            while (mesh && groundBox3.intersectsBox(meshBox3)) {
              ground.position.y -= 0.001;
              groundBox3.setFromObject(ground);
            }

            const sun = new Three.DirectionalLight(0xffffff, .75);
            sun.position.set(10, 10, 10);
            sun.castShadow = true;
            sun.shadow.mapSize.width = 128;
            sun.shadow.mapSize.height = 128;
            scene.add(sun);

            scene.add(new Three.HemisphereLight(0xeeeeee, 0x19bbdc, 1.));

            this.setState({ loadingPreview: false });

            setTimeout(() => {
              this.setState({ previewReady: true });
            }, 1000);
          }}
        />
      </div>
    )
  }

  render() {
    const { selectedFile, fileError } = this.state;

    if (!selectedFile) {
      return (
        <MissingFileLabel>
          <div className="missing-label" onClick={() => navigate('/')}>
            <h1>
              Loading preview...
            </h1>
          </div>
        </MissingFileLabel>
      );
    }

    if (fileError) {
      return (
        <MissingFileLabel>
          <div className="missing-label" onClick={() => navigate('/')}>
            <h1>
              Oops! This file doesn't exist.
            </h1>
            <h2>
              Upload something new
            </h2>
          </div>
        </MissingFileLabel>
      );
    }

    return (
      <AntWrapper style={{ minWidth: 1200 }}>
        <Layout style={{ height: '100vh', boxSizing: 'border-box' }}>
          <SharedNav key={1} refresh={() => this.forceUpdate()} />
          <Layout style={{ overflow: 'hidden' }}>
            <Content
              style={{
                position: 'relative',
                background: '#ccc',
                margin: 0,
                minHeight: 280,
                overflow: 'scroll'
              }}
            >
              {selectedFile && this.renderFilePreview(selectedFile)}
            </Content>
            <Content
              style={{
                position: 'relative',
                background: '#fff',
                borderTop: 'solid 1px #333',
                minHeight: 24,
                maxHeight: 24,
                textAlign: 'right',
                padding: '0 5px'
              }}
            >
              <Row>
                <Col span={24}>
                  <DebugHelperOption>
                    <Tooltip
                      placement="topRight"
                      title={
                        <>
                          This service is still under development.
                        </>
                      }
                    >
                      v0.1 beta
                    </Tooltip>
                  </DebugHelperOption>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </AntWrapper>
    );
  }
}
