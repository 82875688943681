import React from 'react';
import styled from 'styled-components';
import {
  Layout,
  Menu,
  message,
  Tag,
  Progress
} from 'antd';
import { navigate, navigateTo, Link } from 'gatsby';
import StateService from '../services/state.service';
import EditorService, { authTokenKey } from '../services/editor.service';

const publishTimeKey = 'pt_';

const { Item } = Menu;
const {
  Header,
} = Layout;

const WtlHeader = styled.div`
  display: flex;
  box-sizing: border-box;
  background-color: #444;
  border-bottom: solid 1px #263346;
  color: #fff;
  justify-content: space-between;
  font-size: 10px;
`;

const HeaderLeft = styled.div`
  display: inline-flex;
`;

const HeaderRight = styled.div`
  display: inline-flex;
`;

const HeaderItem = styled.div`
  padding: 10px;
  cursor: pointer;
  user-select: none;

  &[disabled] {
    cursor: default;
  }
`;

const ButtonItem = styled(HeaderItem)`
  transition: background-color .1s ease, color .1s ease;

  span {
    font-size: 10px;
  }

  &[disabled] {
    opacity: .5;
  }

  &:hover:not([disabled]) {
    background-color: ${p => p.color || '#fff'};
    color: #000;
  }

  &:active {
    filter: brightness(75%);
  }
`;

const LoadableItem = styled(HeaderItem)`
  @keyframes loadingPulse {
    0% {
      background: #fff;
      width: 0%;
      left: 0;
      box-shadow: 0 0 10px #fff;
    }

    40% {
      background: #1890ff;
      width: 100%;
      left: 0;
      box-shadow: 0 0 10px #1890ff;
    }

    100% {
      left: 100%;
      width: 0%;
      box-shadow: 0 0 10px #fff;
    }
  }

  position: relative;
  user-select: none;

  ${p => p.loading ? (`
    background-color: transparent !important;
    cursor: default !important;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      content: '';
      background: #1890ff;
      animation: loadingPulse 1s ease-in-out both infinite;
    }
  `) : ''}

  ${p => p.highlight && `
    background-color: #1890ff;
  `}

  ${p => p.underline && `
    cursor: default;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      content: '';
      background: #355dab;
    }
  `}

  ${p => p.disabled && `
    cursor: default;
  `}
`;

export class SharedNav extends React.Component {
  updateInterval = null;

  constructor(props) {
    super(props);

    this.state = {
      target: '',
      logoutIcon: 'fa-door-closed'
    };
  }

  goTo(target) {
    if (target === window.location.pathname) {
      return;
    }

    this.setState({ ...this.state, target });

    navigateTo(target);
  }

  render() {
    const { target } = this.state;

    const location = typeof window !== 'undefined' ? window.location.pathname : '/';

    return (
      <WtlHeader>
        <HeaderLeft>
          <LoadableItem
            onClick={() => this.goTo('/')} loading={target === '/'}
            underline={location === '/'}
            disabled={location === '/'}
          >
            <i className="far fa-fw fa-arrow-left" /> Back to TreasureChest.nl
          </LoadableItem>
        </HeaderLeft>
        <HeaderRight>
          
        </HeaderRight>
      </WtlHeader>
    );
  }
}